import React from "react";
import BeforeLoginFooter from "../../../../containers/BeforeLoginFooter";
import Header from "../../../../containers/Header";

function BeforePrivacyPolicy() {
  return (
    <div>
      <div>
        <Header />
      </div>
      <div>
        <main className="main">
          <div className="container">
            <h2 className="sectionTitle"> Privacy Policy</h2>
            <div className="privay-content">
              <p>
                Data Controller Name: Casino Club 888. This site is a beta site
                and is on a beta testing mode.
              </p>
              <p>
                As a data controller, casinoclub888.com is managed by Casino
                Club 888 (hereinafter referred to as CC888) has a legal
                obligation under gambling regulations to process personal data
                from the player for the purposes of allowing him/her access to
                use its services and in order to allow him/her to participate in
                games and provide him/her with ancillary services from any third
                party that has a tie up with us. The user has to verify the
                credentials of the third party before using their services. Our
                services to you is just a link of the services offered to you
                from a third party. The users will have to verify the services
                provided from the third party and use the services at your own
                risk and consequences.
              </p>
              <p>
                The purpose of this notice is to inform you about our policies
                for the collection, use and disclosure of personal information.
                Your information will not be shared with anyone except for the
                parties which has a tie up with us.
              </p>
              <p>
                We reserve the right to update this privacy notice at any time
                without prior notice. CC888 shall at all times process data in
                accordance to the privacy notice that you have consented to at
                the time of your consent. Upon a change in policy, we will alert
                you with a website banner notice; your continued use of the
                website and/or its services will constitute your consent to the
                updated notice.
              </p>
              <p>
                CC888 is deeply committed to protecting and respecting your
                privacy and also ensuring your security when you use our
                Services. Accordingly, we have put in place this Privacy Policy
                to help you understand what we do with your personal data and
                how we protect and process it.
              </p>
              <p>
                CC888 reserves the right to change the Privacy Policy at any
                given time. If this shall happen, we will notify you of any
                material changes before they come into effect. We shall inform
                you of any such amendments by email where you will be redirected
                to the revised privacy policy on our website.
              </p>
              <p>
                We encourage you to read the Privacy Policy carefully and use it
                to make informed decisions. By visiting the website, mobile apps
                or other online properties, or by creating an account through
                our services, you hereby indicate that you have read and
                understood this Privacy Policy.
              </p>
              <p>This Privacy Policy sets out:</p>
              <ul>
                <li>• General information</li>
                <li>• Contact us</li>
                <li>• Data Processing</li>
                <li>• Data we collect</li>
                <li>• Who we share your data with</li>
                <li>• Public messages</li>
                <li>• How we store your data</li>
                <li>• Your rights</li>
              </ul>

              <h4>Contact Us</h4>
              <p>
                CC888 Limited is a data controller based site which is on a beta
                testing mode.
              </p>
              <p>
                If you have any questions regarding your personal data please
                contact our Data Protection Officer at{" "}
                <a href="mailto:club888.casino@gmail.com">
                  club888.casino@gmail.com
                </a>{" "}
              </p>
              <h4>Personal Information</h4>
              <p>
                During the registration process and when you subsequently use
                your CC888 account, you are requested to submit specific
                personal information.
              </p>
              <p>
                The first type of information which we collect is information
                that identifies or may reasonably identify an individual may
                consist of the following but not limited to:
              </p>
              <ul className="Info">
                <li>
                  <p>• Account Details</p>
                  When you open an account and register to use our services, you
                  are asked to provide certain details about yourself, such as
                  your first and last name, date of birth, phone/mobile number,
                  email address, gender, home or another physical address.
                </li>
                <li>
                  <p>• Governmental-issued ID:</p>
                  In certain instances, you will be required to provide a copy
                  of a governmental-issued ID, for identity verification
                  process. Please note that this information is required for
                  certain features of our Services.
                </li>
                <li>
                  <p>• Gaming information:</p>
                  In order for us to provide our services, we automatically
                  record different information related to your account and your
                  use of our services, including with respect to your unique
                  user name, password combination for your account,
                  transactions, deposits, balances, bets, withdraws, bonuses and
                  winnings. We also collect information regarding potential
                  gambling problems or addictions, based on your pattern of
                  gambling across our services, in order to ensure a responsible
                  gaming environment and as required by law.
                </li>
                <li>
                  <p>• Financial information</p>
                  In order for you to enjoy all of the functions of the Services
                  (e.g place bets, perform purchases, play in tournaments,
                  etc.), payment information will be collected from you, such as
                  your preferred currency of deposit, credit card number and
                  bank account details.
                </li>
                <li>
                  <p>• Voluntary information</p>
                  We also collect information which you provide us voluntarily,
                  such as information that you have provided while contacting us
                  via email or telephone or signup forms on our website. For
                  example, when you respond to communications from us via email
                  or share additional information about yourself through your
                  use of the services, such as on the chat feature and in games.
                  This includes information you are willing to share with us for
                  the purpose of improving and developing our relationship with
                  you, as well as for maintaining your stratification from our
                  services (i.e your marital status and family, your hobbies and
                  interests, and other information you share with us through
                  surveys)
                </li>
                <li>
                  <p>• Device information:</p>
                  We collect specific types of connection details and
                  information with regard to your device, software or hardware
                  that may identify you, such as: device’s unique identifiers
                  (e.g. UDID, IMEI and MAC address), browser fingerprinting, IP
                  address and geo-location data.
                </li>
                <li>
                  <p>• Telephone calls:</p>
                  We record or monitor telephone calls for customer support,
                  training and/or security purposes.
                </li>
                <li>
                  <p>• Events </p>
                  During our events, we conduct interview and take photos and
                  videos, which may feature attendees, speakers, sponsors or
                  exhibitors. Where photos and videos are taken that feature you
                  as an attendee, speakers, sponsors or exhibitors. Where photos
                  and videos are taken that feature you as an attendee, speaker,
                  sponsor or exhibitor, we may use your interview for
                  promotional purposes (such as by quoting you)
                </li>
                <li>
                  <p>• Social network:</p>
                  When you register through your social network account, to use
                  the services or connect your player account with such account,
                  we will have access to basic information from your social
                  network account, such as your name, birthdate, profile picture
                  and friend’s list, as well as information you made publicly
                  available on such account.
                  <br />
                  In addition, when using the Services, you may be able via your
                  social network to “Invite a Friend” to use the services.
                  Should the person accept your invitation, we may obtain
                  personal information from him/her such as: name, email
                  address, phone number and date of birth. We will use the
                  personal information for the purposes set out herein and
                  disclose such information only to third parties as detailed in
                  this privacy policy.
                </li>
                <li>
                  <p>• Information we collect from third parties:</p>
                  We collect personal information from third party service
                  providers, such as information about your credit history from
                  credit agencies and other financial information which is
                  relevant to the provision of the services, as well as
                  information which is gathered in order to verify your identity
                  and prevent fraudulent or illegal activity.
                </li>
                <li>
                  <p>• Others</p>
                  Once you have created an account and have become a CC888
                  customer, additional information is processed on an on-going
                  basis. CC888 may also collect:
                  <br />
                  a) A record of all internet communications and details of your
                  visits to our Websites to ensure your site visitation runs
                  smoothly and effortlessly according to your preferences in
                  games, etc. <br /> b) Payment information and details of
                  transactions you carry out through the site, including your
                  payment, withdrawals and wagers <br /> c) Communications with
                  us or our employees, for examples, calls, emails, and messages
                  sent through the live chat service <br /> d) Any additional
                  information you submit through the website for example on
                  forums, chat rooms, comment sections or in response to quizzes
                  and questionnaires <br /> e) Pages that you visited including
                  what you have clicked on. Please view our Cookie Policy below
                  for more information <br /> f) Sometimes we may also request
                  photo ID, proof of address, and source of funds <br /> g)
                  Occasionally we may also access your public records (eg.: news
                  articles, social media, forums, official records such as the
                  electoral roll).
                </li>
                <li>
                  To protect both you and us, we use services provided by third
                  parties to help us decide whether to accept transactions from
                  you by assessing your method of payment, and the devices you
                  use to visit our Websites. These third-party services check
                  whether the device or method of payment has been identified
                  with fraudulent or abusive transactions in the past, such as
                  reported instances of identity theft, promotion abuse or
                  credit card fraud.
                </li>
              </ul>

              <h4>Non-personal Information</h4>
              <p>
                The second type of information is un-identified and
                non-identifiable information pertaining to the users of our
                services, which may be made available or gathered via the
                player’s use of the services (“Non-personal information”).
              </p>
              <p>
                Non-personal information which is being collected consists of
                technical information and aggregated usage information, and may
                contain, among other things, the User’s operating system, type
                of browser, screen resolution, browser and keyboard language,
                the user’s ‘click-stream’ and activities on the services, the
                period of time the user visited the services and related time
                stamps, etc.
              </p>
              <p>
                For avoidance of doubt, any non-personal information connected
                or linked to any personal information shall be deemed as
                personal information as long as such connection or linkage
                exists.
              </p>
              <p>
                Types of non-personal information we collect from or about you:
              </p>
              <ul className="Info">
                <li>
                  a) Log-in history and technical information: In order to
                  enhance the functionality of the services and to provide you
                  with a better user experience, we collect technical
                  information transmitted by your device, including certain
                  software and hardware information (e.g. the type of browser
                  and operating system your device uses, language preference,
                  access time and the domain name of the website from which you
                  linked to the services, etc.)
                </li>
                <li>
                  b) Gameplay information: We record game play information
                  including amongst other things, your deposits, bets, bonuses,
                  game sessions duration and your high scores. We may also share
                  and publish such information through any of our platforms.
                </li>
                <li>
                  c) Device and connection information: If you download the
                  software to your device, we may collect information from the
                  particular device you are using, for security and fraud
                  detection and prevention purposes. For example, we may gather
                  information with regard to other software which is running
                  simultaneously with the software for detecting if you are
                  using software which is associated with fraudulent activity
                  (e.g robots, malware, etc.) or checking if the connection you
                  are using is via a VPN or proxy.
                </li>
                <li>
                  d) Analytics information: We collect information about your
                  use of the services, such as applications’ usage, log files,
                  user activity (e.g. pages viewed, the amount of time spent on
                  particular pages, online browsing, clicks, actions, etc.),
                  time stamps, alerts, etc. This information is collected for
                  amongst other things troubleshooting errors and bugs as well
                  as for research and analytics purposes about your use of the
                  services.
                </li>
                <li>
                  e) Anonymous information: We may anonymize or de-identify the
                  information collected by the services or via other means so
                  that the information cannot, on its own, personally identify
                  you. Our use and disclosure of such aggregated or
                  de-identified information is not subject to any restrictions
                  under this Privacy Policy.
                </li>
                <li></li>
              </ul>

              <h4>How we use your personal data</h4>
              <p>
                We use your personal data to try and provide you with the
                ultimate CC888 experience whilst ensuring we meet regulatory
                requirements, legal obligations and business related
                requirements. We process your personal data for the purposes of:
              </p>
              <ul className="Info">
                <li>
                  <p>
                    • Creating your account and to authenticate your access to
                    the features of our services
                  </p>
                  We use your personal data such as your name, date of birth and
                  email address to help set up, manage and update your gaming
                  account, and, if necessary, to contact you (according to your
                  preferences) via your registered contact information in
                  connection with the management and operation of your account.
                  <br /> We also use your personal data such as your device
                  type, location and browser preferences to manage your account.
                  We want to ensure that you experience the best version of
                  CC888 on your device and are authorised to use CC888 in your
                  current location. We do this based on our legal obligations
                  and legitimate interests to provide you services.{" "}
                </li>
                <li>
                  <p>
                    • Ensuring our Websites function correctly and identifying
                    any technical issues
                  </p>
                  As we want to give our customers the ultimate CC888
                  experience, we may also use your personal data to help with
                  internal research and development, internal testing of the
                  products and to help diagnose system issues and make content
                  and service improvements to the website. This processing is
                  required for the legal obligations and legitimate interests of
                  CC888.
                </li>
                <li>
                  <p>• To provide you with a responsible gaming environment</p>
                  CC888 is committed to ensuring that you gamble responsibly.
                  So, to abide by legal obligations we will process your data to
                  provide you with a safe environment to gamble, as well as to
                  monitor you for potential at-risk behaviour.
                </li>{" "}
                <li>
                  <p>• Minor gambling prevention</p>
                  Our Services are not designed or directed to persons under the
                  age of 18 with respect to the use of the services (“Legally of
                  Age”). If you are not legally of age, you should not download
                  or use the services nor provide any personal information to
                  us.
                  <br /> We reserve the right to access and verify any personal
                  information collected from you. In the event that we become
                  aware that a user of our services is not of legal age to
                  gamble has shared any information, we may discard such
                  information unless it is needed to comply with any legal or
                  statutory obligation binding upon us. If you have any reason
                  to believe that a minor has shared any information with us,
                  please contact us at{" "}
                  <a href="mailto:club888.casino@gmail.com">
                    club888.casino@gmail.com
                  </a>
                </li>
                <li>
                  <p>• Legal and regulatory obligations</p>
                  We are required to process and retain your personal data
                  including but not limited to your name, account number, date
                  of birth and location to prevent fraudulent activity, to
                  detect and/or investigate crimes, and to ensure responsible
                  gambling. Additionally, we may also process your personal data
                  to meet our internal and external reporting requirements. This
                  is required to meet our legal obligations.
                </li>
                <li>
                  <p>• Marketing</p>
                  CC888 will use your personal information, such as your name,
                  home address, email address, telephone number etc., ourselves
                  or by using our third party subcontractors for the purpose of
                  providing you with promotional materials concerning the
                  services as well as products, services, websites and
                  applications, including behavioural advertising. This may
                  relate to:
                  <br />
                  a) Other companies within the Group
                  <br />
                  b) CC888’s business partners and affiliates (collectively:
                  “Marketing Affiliates”), which we believe may interest you.
                  <br />
                </li>
                <p>
                  {" "}
                  We may also share and disclose personal information with our
                  marketing affiliates for the purpose to keep you informed of
                  our latest updates to our services and special offers, which
                  we, or our marketing affiliates, believe are relevant for you.
                  Our marketing affiliates may use this personal information for
                  different marketing techniques, such as direct email, post,
                  SMS and telephone marketing purposes.
                </p>
                <p>
                  We will use your personal information for the purpose of
                  providing you with promotional materials solely where we have
                  a legitimate interest in doing so, or where we have obtained
                  your affirmative consent.
                </p>
                <p>
                  You may at any time decline receiving further marketing offers
                  from us or from our marketing affiliates by either:
                </p>
                <p>
                  Following the guidelines available on the marketing
                  communications (such as selecting the opt-out link inserted in
                  the promotional emails) via “My Accounts” page on the site or
                  by contacting support at{" "}
                  <a href="mailto:club888.casino@gmail.com">
                    club888.casino@gmail.com
                  </a>
                  Please note that even if you unsubscribe from our marketing
                  mailing list, we may continue to send you service-related
                  updates and notifications.
                </p>
                <p>
                  <span>Please note:</span> you can control the delivery of
                  certain advertising or social campaigns through the settings
                  offered by the respective third party platforms (e.g
                  Facebook).
                </p>
                <li>
                  <p>• Prevention of crime</p>
                  CC888 has various policies and procedures designed to detect
                  and prevent fraudulent and illegal activity that may
                  jeopardize or negatively affect the integrity of our services.
                  This includes fraud detection and our Anti-Money Laundering
                  programme. We carry out this processing to comply with our
                  legal obligations, this includes identifying risks associated
                  with your activity on our services.
                </li>
                <li>
                  <p>
                    • Processing your online financial and wagering transactions
                  </p>
                  We need to use your personal data such as your account
                  details, payment type(s), deposit method(s) and wager history
                  to allow you to deposit and withdraw, and for our regulatory
                  reporting. This is to comply with our Terms and Conditions and
                  legal obligations.
                </li>
                <li>
                  <p>• Profiling and your preferences</p>CC888 will create new
                  personal data about you by processing your data including but
                  not limited to your betting and gameplay behaviour, interests,
                  location and age to develop new features, functionality,
                  products and services that are tailored to you. This will help
                  us to ensure that:
                  <br />
                  a) The technical help you may need is in line with the device
                  that you use, and
                  <br />
                  b) The promotions you receive are in line with your
                  preferences, and
                  <br />
                  c) The customer support you receive is in line with your
                  jurisdiction. <br />
                  d) To conduct analytics, statistical and research (such as by
                  compiling aggregated reports about your usage of certain
                  features of our services)
                  <br />
                  e) To enable us to further develop, customize and improve the
                  services based on the player’s common preference and uses
                  <br />
                  Additionally, this profiling is in our legal interests, as
                  profiling of your personal data is for reasons such as meeting
                  our legal requirements with regards to anti-money laundering,
                  age verification, prevention of fraud, sports integrity and
                  responsible gambling.
                </li>
                <li>
                  <p>• Risk Management</p>
                  We review customer profile and betting data for the purposes
                  of enforcing our terms and conditions managing our financial
                  liabilities and risk on events and markets. This is both to
                  comply with our legitimate interest and legal obligations.
                  <br />
                  Where you have not consented (or have withdrawn your consent
                  to the processing of your personal information by us, we may
                  continue to process your personal information for the
                  following reasons:
                  <br />
                  a) Where processing is required for the performance of the
                  contract (i.e. Terms & Conditions) between you and us <br />
                  b) Where processing is necessary for compliance with a legal
                  obligation to which we are subject
                  <br />
                  c) Where processing is necessary for the purpose of a
                  legitimate interest of the Group (including marketing).
                </li>
              </ul>

              <h4>Who we share your personal data with</h4>
              <p>
                Certain trusted third party companies provide services essential
                to our ability to offer our gambling products to you, for
                example those that supply the software and games for our
                Websites, banking and payment services, identity verification,
                fraud prevention and risk management. We may be required to
                share some of your information with these companies, to be able
                to offer our Services to you. In these instances, we ensure that
                the third party has appropriate security and privacy
                arrangements in place, so that your data is protected.
              </p>
              <p>
                CC888 may also be required to disclose your information to
                government authorities, such as the police or our regulators, to
                comply with our legal obligations. We will also disclose
                information as required to enforce our terms of use as stated in
                the Terms and Conditions.
              </p>
              <p>
                In the event of the sale of our business, or other business
                event, which results in us having to transfer your personal data
                to a third party, you will be notified via email, or notice
                posted on our website, to explain the identity of the new data
                controller and your options regarding your personal data.
              </p>
              <p>
                Our site may contain links to and from the Websites of third
                party organisations. Please note that these Websites may have
                their own privacy policies and that we do not accept any
                responsibility whatsoever for these policies. Please check these
                policies carefully before you submit any personal data.
              </p>
              <p>
                CC888 does not sell or rent personal information to
                third-parties. We do not share your personal information, except
                as provided in this privacy policy.
              </p>
              <p>
                CC888’s employees who have access to, or are associated with the
                processing of the player’s personal information, have signed
                confidentiality agreements to respect the confidential nature of
                the player’s information pursuant to applicable gaming, data
                protection and privacy laws.
              </p>

              <h4>Public Messages</h4>
              <p>
                Any information that is disclosed in a public area of the
                website is public information and users should exercise caution
                when deciding to disclose personal information on the website.
                If you voluntarily disclose information in a public area of the
                website, such as a message board, that information may be
                collected and used by a third-party to send you unsolicited
                messages, advertisements or for other purposes. We do not
                control the actions of these third parties in public areas of
                the website. If you disclose information in a public area, you
                do so at your own risk.
              </p>

              <h4>How we store your personal data</h4>
              <p>
                Where we work, your data is processed by staff who work for us
                or for one of our suppliers. Such staff may be engaged in, among
                other things, customer services, the processing of your payment
                details and risk/fraud management.
              </p>
              <p>
                We ensure there are appropriate contractual controls over any
                third party that helps us process your data, which ensuring that
                your rights are upheld and your data is secure.
              </p>
              <p>
                We store your data for as long as you are an active customer
                with us. Once you are no longer our customer, we will hold your
                personal data on our systems for as long as is necessary to
                comply our legal obligations in your jurisdiction, such those
                imposed by tax, gambling and laws.
              </p>
              <p>
                While we use all reasonable efforts to protect your personal
                information, however, we cannot guarantee the absolute security
                of your data submitted through our websites.
              </p>

              <h4>Your Rights</h4>
              <p>
                You are a data subject, your personal data belongs to you, so
                you have rights over your personal data. Some of these rights
                only apply in certain circumstances.
              </p>
              <p>
                If you want more information you can contact
                <a href="mailto:club888.casino@gmail.com">
                  club888.casino@gmail.com
                </a>{" "}
                These are your fundamental rights:
              </p>
              <ul className="Info">
                <li>
                  <p>• Access</p>You have the right to know whether personal
                  data concerning you is being processed and can also request a
                  copy of your data.
                </li>
                <li>
                  <p>• Rectification</p>You have the right to easily amend
                  incorrect personal data about yourself.
                </li>
                <li>
                  <p>• Erasure</p>You have the right to request ‘to be
                  forgotten’. You can ask us to delete you from our database.
                </li>
                <li>
                  <p>• Restrict Processing</p>You have the right to request ‘to
                  be forgotten’. You can ask us to delete you from our database.
                </li>
                <li>
                  <p>• Be Informed</p>We need to let you know what we do with
                  your personal data in this privacy policy or when you ask us.
                </li>
                <li>
                  <p>• Data Portability</p>You can ask us to provide the
                  personal data you provided us in a machine- readable format
                </li>
              </ul>
              <p>
                Please note that these rights are not absolute and requests are
                subject to any applicable legal requirements, including gambling
                regulations and other legal and ethical reporting or document
                retention obligations.
              </p>
              <p>
                For any further queries related to your rights, you can submit
                an email to{" "}
                <a href="mailto:club888.casino@gmail.com">
                  club888.casino@gmail.com
                </a>
              </p>
            </div>
          </div>
        </main>
      </div>
      <div>
        <BeforeLoginFooter />
      </div>
    </div>
  );
}

export default BeforePrivacyPolicy;

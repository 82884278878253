import React from "react";

const ComingSoon = () => {
  return (
    <div>
      <div className="coming_soon">
        <span>C</span>
        <span>O</span>
        <span>M</span>
        <span>I</span>
        <span>N</span>
        <span>G</span>
        <span>S</span>
        <span>O</span>
        <span>O</span>
        <span>N</span>
      </div>
    </div>
  );
};

export default ComingSoon;

export const EvolutionGames = {
  AutoRoulette: {
    href: "/casino/evolution/AutoRoulette",
    code: "1000123",
    casino: "evolution",
    provider: "",
    homeUrl: "",
    cover: { src: "./images/roulette/auto-roulette.jpg", alt: "" },
  },

  thirtytwoCards: {
    href: "/casino/ezugi/thirtytwoCards",
    code: "228002",
    casino: "ezugi",
    provider: "",
    homeUrl: "",
    cover: { src: "./images/card/casino057.png", alt: "" },
  },
  Baccarat: {
    href: "/casino/ezugi/Baccarat",
    code: "106",
    casino: "ezugi",
    provider: "",
    homeUrl: "",
    cover: { src: "./images/baccarat/casino034.png", alt: "" },
  },
  Blackjack: {
    href: "/casino/ezugi/Blackjack",
    code: "201",
    casino: "ezugi",
    provider: "",
    homeUrl: "",
    cover: { src: "./images/blackjack/casino039.png", alt: "" },
  },
  Megaball: {
    href: "/casino/evolution/Megaball",
    code: "1000141",
    casino: "ezugi",
    provider: "",
    homeUrl: "",
    cover: { src: "./images/dice/casino058.png", alt: "" },
  },
  sicbodice01: {
    href: "/casino/ezugi/sicbodice01",
    code: "228001",
    casino: "ezugi",
    provider: "",
    homeUrl: "",
    cover: { src: "./images/dice/casino058.png", alt: "" },
  },
  CrazyTime: {
    href: "/casino/evolution/CrazyTime",
    code: "1000148",
    casino: "ezugi",
    provider: "",
    homeUrl: "",
    cover: { src: "./images/roulette/auto-roulette.jpg", alt: "" },
  },
  DragonTiger1: {
    href: "/casino/evolution/DragonTiger1",
    code: "1000074",
    casino: "ezugi",
    provider: "",
    homeUrl: "",
    cover: { src: "./images/roulette/auto-roulette.jpg", alt: "" },
  },
  DoubleBallRoulette: {
    href: "/casino/evolution/DoubleBallRoulette",
    code: "1000084",
    casino: "ezugi",
    provider: "",
    homeUrl: "",
    cover: { src: "./images/roulette/auto-roulette.jpg", alt: "" },
  },
};

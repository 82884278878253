import React from "react";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";

import HomeIcon from "./../../assets/images/games-icon/home-icon1.png";
import CasinoIcon from "./../../assets/images/games-icon/Livecasino.png";
import CasinoIcon1 from "./../../assets/images/games-icon/sportsbook.png";
import InplayIcon from "./../../assets/images/games-icon/In-play.png";
import CricketIcon from "./../../assets/images/games-icon/Cricket.png";
import SoccerIcon from "./../../assets/images/games-icon/Soccer.png";
import TennisIcon from "./../../assets/images/games-icon/Tennis.png";
import AviatorIcon from "./../../assets/images/games-icon/Aviator.png";
import EzugiIcon from "./../../assets/images/games-icon/Ezugi.png";
import EvolutionIcon from "./../../assets/images/games-icon/slots.png";
import PromotionIcon from "./../../assets/images/games-icon/Promotion.png";
import GolfIcon from "./../../assets/images/games-icon/golficon.png";
import RugbyIcon from "./../../assets/images/games-icon/rugby-icon.png";
import BoxingIcon from "./../../assets/images/games-icon/boxing-icon.png";
import horseracingIcon from "./../../assets/images/games-icon/horseracing-icon.png";
import EsportsIcon from "./../../assets/images/games-icon/esports-icon.png";
import VolleyballIcon from "./../../assets/images/games-icon/volleyball-icon.png";
import CyclingIcon from "./../../assets/images/games-icon/cycling-icon.png";
import SnookerIcon from "./../../assets/images/games-icon/snooker-icon.png";
import BaseballIcon from "./../../assets/images/games-icon/baseball-icon.png";
import NetballIcon from "./../../assets/images/games-icon/netball-icon.png";
import BasketballIcon from "./../../assets/images/games-icon/basketball-icon.png";
import IceHockeyIcon from "./../../assets/images/games-icon/icehockey-icon.png";
import HandballIcon from "./../../assets/images/games-icon/handball-icon.png";
import DartsIcon from "./../../assets/images/games-icon/dart-icon.png";
import GreyhoundIcon from "./../../assets/images/games-icon/greyhoundracing-icon.png";
import PoliticsIcon from "./../../assets/images/games-icon/political-icon.png";

const LeftBarSports = () => {
  const navigate = useNavigate();
  const { sportsData, oddsData, showBetSlip } = useSelector(
    (state) => state.sports
  );
  const { isAuth } = useSelector((state) => state.auth);

  const handleEventClick = (event) => {
    navigate(
      `/detail-page/${event?.marketSlug}/${event?.matchid}/${event?.marketid}/${event?.SportId}`
    );
  };

  const tabs = [
    {
      id: 1,
      eventKey: "1",
      name: "In play",
    },
    {
      id: 2,
      eventKey: "2",
      name: "Cricket",
    },
    {
      id: 3,
      eventKey: "3",
      name: "Soccer",
    },
    {
      id: 4,
      eventKey: "4",
      name: "Tennis",
    },
  ];

  const getMatchLength = (id) => {
    let value = sportsData?.find(({ SportId }) => SportId == id)?.values
      ?.length;
    return value || 0;
  };

  return (
    <>
      <div className="leftbarSec">
        <ul>
          <li className="games_link">
            {/* <h6>Quick Links</h6> */}
            <ul>
              <li>
                <div
                  onClick={() => {
                    navigate("/home");
                  }}
                >
                  <img src={HomeIcon} alt="Home Icon" />
                  <span>Home</span>
                </div>
              </li>
              <li>
                <div
                  onClick={() => {
                    navigate("/betby");
                  }}
                >
                  <img src={CasinoIcon1} alt="Casino Icon" />
                  <span>Sportsbook</span>
                </div>
              </li>

              <li>
                <div
                  onClick={() => {
                    navigate("/casino/evolution");
                  }}
                >
                  <img src={CasinoIcon} alt="Casino Icon" />
                  <span>Live Casino</span>
                </div>
              </li>
            </ul>
          </li>

          <li className="games_link">
            {/* <h6>Sports</h6> */}
            <ul>
              <li>
                <div
                  onClick={() => {
                    navigate("/casino/spribe/aviator");
                  }}
                >
                  <img src={AviatorIcon} alt="Casino Icon" />
                  <span>Aviator</span>
                </div>
              </li>
              <li>
                <div
                  onClick={() => {
                    navigate("/casino/ezugi");
                  }}
                >
                  <img src={EzugiIcon} alt="Casino Icon" />
                  <span>Ezugi</span>
                </div>
              </li>
              <li>
                <div
                  onClick={() => {
                    navigate("/Slots");
                  }}
                >
                  <img src={EvolutionIcon} alt="Casino Icon" />
                  <span>Slots</span>
                </div>
              </li>
              {/* <li>
                <div
                  onClick={() => {
                    navigate("/");
                  }}
                >
                  <img src={PromotionIcon} alt="Casino Icon" />
                  <span>Promotion</span>
                </div>
              </li> */}
            </ul>
          </li>
        </ul>
      </div>
    </>
  );
};

export default LeftBarSports;
//
